<template>
  <div
    v-if="allowAccess"
    class="report"
  >
    <van-nav-bar
      :title="$t('sharedReport')"
      fixed
      placeholder
    >
      <template
        v-if="isUserLoggedIn"
        #left
      >
        <van-icon
          class="home-icon"
          name="wap-home-o"
          size="26"
          @click="$router.push({ name: 'dashboard' })"
        />
      </template>
    </van-nav-bar>
    <report-charts
      :diagnosis="diagnosis"
      :diagnosis-abbreviation="diagnosisAbbreviation"
      :metabolism-spec="metabolismSpec"
      :get-saved-dayplans="getSavedDayplans"
      :user-profile="previewedUser"
    />
    <div class="branding__container">
      <branding />
    </div>
  </div>
  <div v-else />
</template>

<script>
import Diagnosis from '@/models/diagnosis';
import SharedReport from '@/models/user-report';
import Branding from '@/components/Branding';
import ReportCharts from '@/components/ReportCharts';
import { getAbbreviation } from '@/helper';

export default {
  components: {
    Branding,
    ReportCharts,
  },
  data() {
    return {
      allowAccess: false,
      previewUserId: false,
      isUserLoggedIn: false,
      diagnosis: null,
      diagnosisAbbreviation: '',
      metabolismSpec: '',
    };
  },

  async created() {
    this.sharedReport = new SharedReport(this.$router);
    this.previewUserData = await this.sharedReport.validateToken();
    this.isUserLoggedIn = this.sharedReport.isUserLoggedIn();

    if (this.previewUserData) {
      this.getUserProfile();
    } else {
      this.$router.push({ name: 'expiredReport' });
    }
  },

  methods: {
    async getUserProfile() {
      this.previewedUser = await this.sharedReport.getUserProfile(this.previewUserData.uid);

      if (Object.keys(this.previewedUser).length) {
        this.allowAccess = true;
        this.previewUserId = this.previewUserData.uid;
        this.nutritionLimits = this.previewedUser.nutritionLimits;
        this.diagnosis = new Diagnosis(this.previewedUser.diagnosisName);
        this.metabolismSpec = `${this.nutritionLimits[this.diagnosis.nutritionKey]} mg`;
        this.diagnosisAbbreviation = getAbbreviation(this.diagnosis.nutritionKey) || this.$i18n.t('protein');
      } else {
        this.$router.push({ name: 'expiredReport' });
      }
    },

    async getSavedDayplans() {
      const dates = await this.sharedReport.getSavedDayplans(this.previewUserData.uid);
      return dates;
    },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.icons-grid {
  align-items: center;
  display: grid;
  gap: config.$spacing-xs;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}

.van-nav-bar {
  .home-icon {
    color: config.$color-darkest;
  }
}
</style>
